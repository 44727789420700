module.exports = {
    'menu.item.dashboard': 'Irányítópult',
    'menu.item.sales-targets': 'Értékesítői tervszámok',
    'menu.item.sales-dashboard': 'Értékesítő Irányítópult',
    'menu.item.customer-partner-report': 'Vevő cég riport',
    'menu.item.customer-partner-coupon-report': 'Vevő cég kupon riport',
    'menu.item.products': 'Termékek',
    'menu.item.change-log': 'Change log',
    'menu.item.crm': 'CRM',
    'menu.item.customers': 'Webshop vásárlók',
    'menu.item.supplier-partner': 'Beszállító partnerek',
    'menu.item.customer-partner': 'Vevő partnerek',
    'menu.item.search-keywords': 'Keresések (webshop)',
    'menu.item.complaints': 'Reklamációk (webshop)',
    'menu.item.stock-shortage-subscriptions': 'Termék feliratkozások',
    'menu.item.newsletter-subscriber': 'Hírlevél feliratkozók',
    'menu.item.coupons': 'Kuponok',
    'menu.item.blog': 'Blog',
    'menu.item.other': 'Egyéb',
    'menu.item.stocks': 'Raktár',
    'menu.item.procurement': 'Beszerzés',
    'menu.item.b2b-stock': 'Aktuális raktárkészlet',
    'menu.item.b2b-kn-stock': 'Aktuális KN raktárkészlet',
    'menu.item.stock-history': 'Készlet történet',
    'menu.item.b2b-stock-history': 'Készlet történet ',
    'menu.item.stock': 'Jelenlegi készlet',
    'menu.item.stock-income': 'Betárolás',
    'menu.item.stock-issuing': 'Kitárolás',
    'menu.item.stock-transaction': 'Belső mozgatás',
    'menu.item.customer-partner-stock-reservation': 'Partner foglalás',
    'menu.item.channel-stock-reservation': 'Csatorna foglalás',
    'menu.item.stock-disposal': 'Selejtezés',
    'menu.item.vam-informatika': 'VÁM Informatika',
    'menu.item.dpd': 'DPD',
    'menu.item.stock-product-inventory': 'Leltárazás',
    'menu.item.anyk': 'ANYK',
    'menu.item.pricing': 'Árazás',
    'menu.item.supplier-pricing': 'Beszállítói árazás',
    'menu.item.channel-pricing': 'Listaárak',
    'menu.item.customer-partner-pricing': 'Vevő partner árak',
    'menu.item.orders': 'Megrendelések',
    'menu.item.b2c-order': 'Webshop rendelések',
    'menu.item.b2b-order': 'B2B rendelések',
    'menu.item.reports': 'Riportok',
    'menu.item.sales': 'Eladások',
    'menu.item.product-sales-report': 'Eladások termékenként',
    'menu.item.order-sales-report': 'Eladások megrendelésenként',
    'menu.item.supplier-stock-report': 'Tárolási riport',
    'menu.item.product-sales-by-orders-report': 'Forgalmi kimutatás riport',
    'menu.item.supplier-stock-report-new': 'Új Tárolási riport',
    'menu.item.stock-rotation-report': 'Készlet forgás',
    'menu.item.settings': 'Beállítások',
    'menu.item.wineries-and-breweries': 'Borászatok/Főzdék',
    'menu.item.winery': 'Borászatok (webshop)',
    'menu.item.brewery': 'Főzdék',
    'menu.item.user': 'Felhasználók',
    'menu.item.attributes': 'Termék jellemzők',
    'menu.item.kn-attributes': 'KN jellemzők',
    'menu.item.categories': 'Termék kategóriák',
    'menu.item.slider': 'Slider (webshop)',
    'menu.item.product-orders': 'Termék rendezés (webshop)',
    'menu.item.main-menu-settings': 'Főmenü (webshop)',
    'menu.item.bundle': 'Csomagok',
    'menu.item.region': 'Régiók (webshop)',
    'menu.item.depot': 'Telephelyek',
    'menu.item.warehouse': 'Raktárak',
    'menu.item.inventory': 'Raktár struktúra',
    'menu.item.storage': 'Tárhelyek',
    'menu.item.mozaik': 'MOZAIK',
    'menu.item.stock-site': 'Telephely',
    'menu.item.stock-storage': 'Raktár',
    'menu.item.b2b': 'B2B',
};
