"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TotalAmount = void 0;
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var React = require("react");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var TotalAmount = (function (_super) {
    __extends(TotalAmount, _super);
    function TotalAmount() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TotalAmount.prototype.render = function () {
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'display-flex' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 50 },
                React.createElement("h4", null,
                    "Total: ",
                    (0, currency_parser_1.moneyFormat)(this.calculateTotal()))));
    };
    TotalAmount.prototype.calculateTotal = function () {
        var e_1, _a;
        var _b;
        var prices = (_b = this.props) === null || _b === void 0 ? void 0 : _b.prices;
        var total = 0;
        try {
            for (var _c = __values(Object.entries(prices !== null && prices !== void 0 ? prices : {})), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), _1 = _e[0], data = _e[1];
                var price = data === null || data === void 0 ? void 0 : data.price;
                var amount = data === null || data === void 0 ? void 0 : data.amount;
                if (isNaN(price) || isNaN(amount)) {
                    continue;
                }
                total += price * amount;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return total;
    };
    return TotalAmount;
}(abstract_component_1.AbstractComponent));
exports.TotalAmount = TotalAmount;
