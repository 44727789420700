"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModifierTable = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var currency_parser_1 = require("../../../../common/libs/currency-parser");
var badge_1 = require("../../../../components/libs/badge/badge");
var handle_toasts_1 = require("../../../../components/libs/handle.toasts");
var modal_1 = require("../../../../components/modal/modal");
var confirm_modal_1 = require("../../b2b-customer-partner-pricing/components/confirm.modal");
var end_of_day_parser_1 = require("../../b2b-customer-partner-pricing/components/libs/end-of-day-parser");
var get_status_1 = require("../../b2b-customer-partner-pricing/components/libs/get-status");
var ModifierTable = (function (_super) {
    __extends(ModifierTable, _super);
    function ModifierTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = __assign(__assign({}, _this.state), { IsActive: false, priceCancellationTarget: null });
        return _this;
    }
    ModifierTable.prototype.render = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'col' }, this.renderTable()),
            React.createElement("div", { className: 'col' },
                React.createElement(modal_1.Modal, { col: { md: 20, lg: 10 }, ref: function (ref) { return (_this.$confirmModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-customer-partner-pricing.discount.over')); }, renderModalContent: function (ctx) { return React.createElement(confirm_modal_1.ConfirmModal, { onClose: function () { return _this.$confirmModal.close(); }, onConfirm: function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4, this.cancel()];
                                    case 1:
                                        _a.sent();
                                        this.$confirmModal.close();
                                        return [2];
                                }
                            });
                        }); }, message: (0, trans_1.trans)('b2b-customer-partner-pricing.are.you.sure') }); } })),
            React.createElement("div", { className: 'col display-flex justify-content-center my-3' },
                React.createElement(button_1.Button, { className: 'button-error-inverze button-size-normal', title: 'Új akció megadása', iconLeft: 'fas fa-plus', onClick: function () { return _this.props.openEdit(); } })));
    };
    ModifierTable.prototype.cancel = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var modifierId, itemId, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        modifierId = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.product.channelPriceModifier) === null || _b === void 0 ? void 0 : _b._id;
                        itemId = (_c = this.state.priceCancellationTarget) === null || _c === void 0 ? void 0 : _c.itemId;
                        if (!modifierId || !itemId) {
                            return [2];
                        }
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        return [4, this.repository.put("/b2b/channel-pricing/modifier/cancel-price/".concat(modifierId), { params: { itemId: itemId } })];
                    case 2:
                        _d.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres törlés. A módosító adatok módosultak');
                        this.props.onRefreshRequest();
                        return [3, 4];
                    case 3:
                        e_1 = _d.sent();
                        console.log({ cancel: e_1 });
                        handle_toasts_1.HandleToasts.error('Sikertelen törlés');
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ModifierTable.prototype.renderTable = function () {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic w-100', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItem(_this.props.items); } });
    };
    ModifierTable.prototype.renderItem = function (items) {
        var _this = this;
        var _a, _b;
        var modifierId = (_b = (_a = this.props.product) === null || _a === void 0 ? void 0 : _a.channelPriceModifier) === null || _b === void 0 ? void 0 : _b._id;
        return items === null || items === void 0 ? void 0 : items.map(function (item, index) {
            var status = (0, get_status_1.getStatus)(item);
            return React.createElement(table_body_row_1.TableBodyRow, { key: index, className: "".concat(status === 'past'
                    ? 'color--neutral-4'
                    : status === 'current'
                        ? 'fw-700'
                        : '') },
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 }, moment(item.from).format('YYYY-MM-DD')),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 }, (item === null || item === void 0 ? void 0 : item.to) ? moment(item.to).format('YYYY-MM-DD') : '-'),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                    React.createElement(badge_1.Badge, { title: status === 'past'
                            ? 'LEJÁRT'
                            : status === 'current'
                                ? 'AKTUÁLIS'
                                : 'JÖVŐBENI', type: status === 'past'
                            ? 'neutal'
                            : status === 'current'
                                ? 'success'
                                : 'info' })),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 20 }, _this.getModifier(item)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 25 }, _this.renderCancelAction(status, modifierId, item)),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 5, className: 'justify-content-start' }, status === 'future' && React.createElement(button_1.Button, { title: React.createElement("i", { className: 'fas fa-pencil' }), className: 'button-neutral-link', onClick: function () { return _this.props.openEdit(item); } })),
                React.createElement(table_body_cell_1.TableBodyCell, { width: 5, className: 'justify-content-start' }, status === 'future' && React.createElement(button_1.Button, { title: React.createElement("i", { className: 'fas fa-trash-can' }), className: 'button-neutral-link', onClick: function () { var _a, _b; return _this.props.deleteModifier((_b = (_a = _this.props.product) === null || _a === void 0 ? void 0 : _a.channelPriceModifier) === null || _b === void 0 ? void 0 : _b._id, item.id); } })));
        });
    };
    ModifierTable.prototype.renderCancelAction = function (status, modiferId, item) {
        var _this = this;
        switch (status) {
            case 'future':
                return React.createElement(button_1.Button, { onClick: function () {
                        _this.setState({ priceCancellationTarget: { modiferId: modiferId, itemId: item.id } });
                        _this.$confirmModal.open();
                    }, title: (0, trans_1.trans)('b2b-channel-pricing.modal.button.discount.end'), iconLeft: 'fas fa-trash', className: 'button-primary-normal button-size-normal' });
            case 'current':
                return React.createElement(button_1.Button, { onClick: function () {
                        _this.setState({ priceCancellationTarget: { modiferId: modiferId, itemId: item.id } });
                        _this.$confirmModal.open();
                    }, title: (0, trans_1.trans)('b2b-channel-pricing.modal.button.discount.end'), iconLeft: 'fas fa-trash', className: 'button-primary-normal button-size-normal' });
            default:
                return "".concat((0, end_of_day_parser_1.endOfDayParser)(item.to));
        }
    };
    ModifierTable.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('start'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('end'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('status'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 45 }, this.setHeaderCell('modifier'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 5 }, this.setHeaderCell('edit'), { className: 'justify-content-center' })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 5 }, this.setHeaderCell('delete'), { className: 'justify-content-center' })));
    };
    ModifierTable.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("b2b-channel-pricing.edit.modal.table.header.".concat(p)),
            property: p
        };
    };
    ModifierTable.prototype.getModifier = function (modifier) {
        var _a, _b, _c, _d;
        if (((_a = modifier === null || modifier === void 0 ? void 0 : modifier.value) === null || _a === void 0 ? void 0 : _a.type) === 'amount') {
            return "".concat((0, currency_parser_1.splitToMoney)((_b = modifier === null || modifier === void 0 ? void 0 : modifier.value) === null || _b === void 0 ? void 0 : _b.value), " Ft");
        }
        if (((_c = modifier === null || modifier === void 0 ? void 0 : modifier.value) === null || _c === void 0 ? void 0 : _c.type) === 'percentage') {
            return "".concat((_d = modifier === null || modifier === void 0 ? void 0 : modifier.value) === null || _d === void 0 ? void 0 : _d.value, "%");
        }
    };
    return ModifierTable;
}(abstract_component_1.AbstractComponent));
exports.ModifierTable = ModifierTable;
