"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PERMISSIONS = exports.getCrudPermissions = void 0;
var getCrudPermissions = function (base) { return ({
    create: "".concat(base, ".create"),
    read: "".concat(base, ".read"),
    update: "".concat(base, ".update"),
}); };
exports.getCrudPermissions = getCrudPermissions;
exports.PERMISSIONS = {
    MISC: (0, exports.getCrudPermissions)('misc'),
    DASHBOARD: (0, exports.getCrudPermissions)('dashboard'),
    PRODUCTS: (0, exports.getCrudPermissions)('products'),
    CRM: {
        customers: (0, exports.getCrudPermissions)('customers'),
        supplierPartners: (0, exports.getCrudPermissions)('supplierPartners'),
        customerPartners: (0, exports.getCrudPermissions)('customerPartners'),
        searchKeywords: (0, exports.getCrudPermissions)('searchKeywords'),
        complaints: (0, exports.getCrudPermissions)('complaints'),
        newsletterSubscriber: (0, exports.getCrudPermissions)('newsletterSubscriber'),
        coupons: (0, exports.getCrudPermissions)('coupons'),
        stockShortageSubscriptions: (0, exports.getCrudPermissions)('stockShortageSubscriptions'),
    },
    STORAGE: {
        procurement: (0, exports.getCrudPermissions)('procurement'),
        actualStock: (0, exports.getCrudPermissions)('actualStock'),
        knStock: (0, exports.getCrudPermissions)('knStock'),
        stockHistory: (0, exports.getCrudPermissions)('stockHistory'),
        stockIncome: (0, exports.getCrudPermissions)('stockIncome'),
        stockIssue: (0, exports.getCrudPermissions)('stockIssue'),
        stockTransaction: (0, exports.getCrudPermissions)('stockTransaction'),
        partnerStockReservation: (0, exports.getCrudPermissions)('partnerStockReservation'),
        channelStockReservation: (0, exports.getCrudPermissions)('channelStockReservation'),
        stockDisposable: (0, exports.getCrudPermissions)('stockDisposable'),
        stockProductInventory: (0, exports.getCrudPermissions)('stockProductInventory'),
        anyk: (0, exports.getCrudPermissions)('anyk'),
    },
    PRICING: {
        supplierPricing: (0, exports.getCrudPermissions)('supplierPricing'),
        channelPricing: (0, exports.getCrudPermissions)('channelPricing'),
        customerPartnerPricing: (0, exports.getCrudPermissions)('customerPartnerPricing'),
    },
    ORDERS: {
        b2cOrders: (0, exports.getCrudPermissions)('b2cOrders'),
        b2bOrders: (0, exports.getCrudPermissions)('b2bOrders'),
    },
    REPORTS: {
        orderSalesReport: (0, exports.getCrudPermissions)('orderSalesReport'),
        productSalesByOrders: (0, exports.getCrudPermissions)('productSalesByOrders'),
        productSalesReport: (0, exports.getCrudPermissions)('productSalesReport'),
        supplierStockReport: (0, exports.getCrudPermissions)('supplierStockReport'),
        stockRotationReport: (0, exports.getCrudPermissions)('stockRotationReport'),
        customerPartnerReport: (0, exports.getCrudPermissions)('customerPartnerReport'),
    },
    SETTINGS: {
        salesTargets: (0, exports.getCrudPermissions)('salesTargets'),
        users: (0, exports.getCrudPermissions)('users'),
        attributes: (0, exports.getCrudPermissions)('attributes'),
        knAttributes: (0, exports.getCrudPermissions)('knAttributes'),
        categories: (0, exports.getCrudPermissions)('categories'),
        regions: (0, exports.getCrudPermissions)('regions'),
        winery: (0, exports.getCrudPermissions)('winery'),
        slider: (0, exports.getCrudPermissions)('slider'),
        mainMenuSettings: (0, exports.getCrudPermissions)('mainMenuSettings'),
        productOrders: (0, exports.getCrudPermissions)('productOrders'),
        b2bDepot: (0, exports.getCrudPermissions)('b2bDepot'),
        b2bWarehouse: (0, exports.getCrudPermissions)('b2bWarehouse'),
        b2bStorage: (0, exports.getCrudPermissions)('b2bStorage'),
        b2bInventory: (0, exports.getCrudPermissions)('b2bInventory'),
    },
};
