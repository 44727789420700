module.exports = {
    'user.list.title': 'Felhasználók',
    'user.list.button-invite.title': 'Új felhasználó meghívása',
    'user.table.header.name': 'Felhasználó neve',
    'user.table.header.identifier': 'E-mail cím',
    'user.table.header.phoneNumber': 'Telefonszám',
    'user.table.header.role': 'Jogkör',
    'user.table.header.status': 'Státusz',
    'user.table.header.createdAt': 'Létrehozva',
    'user.status.active': 'aktív',
    'user.status.inactive': 'inaktív',
    'user.status.invited': 'meghívó kiküldve',
    'user.form.firstName': 'Keresztnév',
    'user.form.lastName': 'Vezetéknév',
    'user.form.phone': 'Telefonszám',
    'user.form.email': 'E-mail',
    'user.form.role': 'Jogkör',
    'user.form.manufacturer': 'Gyártó',
    'user.form.role.admin': 'Adminisztrátor',
    'user.form.role.superadmin': 'Super Admin',
    'user.form.role.b2b-customer': 'Vevő partner',
    'user.form.role.b2b-supplier': 'Beszállító partner (Borász)',
    'user.form.role.b2c-customer': 'Webshop vásárló',
    'user.form.role.finance': 'Pénzügy',
    'user.form.role.delivery': 'Kiszállítás',
    'user.form.role.commercialManager': 'Sales vezető',
    'user.form.role.marketingManager': 'Marketing',
    'user.form.role.procurement': 'Beszerző ',
    'user.form.role.procurement-leader': 'Beszerzési vezető',
    'user.form.role.salesManager': 'Sales',
    'user.form.role.productOwner': 'Product Owner',
    'user.form.role.shop': 'Shop',
    'user.form.role.storekeeper': 'Raktár vezető',
    'user.form.role.telesales': 'Telesales',
    'user.create.screen.title': 'Új felhasználó meghívása',
    'user.update.screen.title': 'Felhaszáló szerkesztése',
    'user.create.button.title': 'Felhasználó létrehozása',
    'user.update.button.title': 'Felhasználó módosítása',
    'user.invitation.button.resend': 'Meghívó újraküldése',
    'user.create.success.toast': 'A felhasználó létrehozása sikeresen megtörtént',
    'user.update.success.toast': 'A felhasználó módosítása sikeresen megtörtént',
    'user.create.invited.toast': 'Kiküldtük a meghívót a felhasználónak',
    'user.deactivation.success': 'A felhasználót sikeresen deaktiváltuk',
    'user.activation.success': 'A felhasználót sikeresen aktiváltuk',
    'user.activation.button.title': 'Aktiválás',
    'user.deactivation.button.title': 'Deaktiválás',
    'user.activation.box.title': 'Ez a felhasználó jelenleg inaktív',
    'user.activation.box.description': 'Amennyiben egy felahsználó aktív úgy lehetősége van bejelentkezni és használni a rendszert. Deaktív felhasználónak a bejelentkezés tiltva van.',
    'user.activation.modal.title': 'Biztosan aktiválod a felhasználót?',
    'user.activation.modal.description': '                            Amennyiben aktiválod a felhasználót, úgy újra lesz lehetősége bejelentkezni a felületre.',
    'user.deactivation.box.title': 'Ez a felhasználó jelenleg aktív',
    'user.deactivation.box.description': 'Amennyiben egy felahsználó aktív úgy lehetősége van bejelentkezni és használni a rendszert. Deaktív felhasználónak a bejelentkezés tiltva van.',
    'user.deactivation.modal.title': 'Biztosan deaktiválod a felhasználót?',
    'user.deactivation.modal.description': 'Amennyiben deaktiválod a felhasználót, úgy nem lesz lehetősége bejelentkezni a felületre.',
    'user.invitation.box.title': 'A felhasználó megkapta a meghívót',
    'user.invitation.box.description': 'Minden felhasználó a létrehozás után kap egy meghívót amin keresztül befejezheti a regisztrációt. Amennyiben a meghívó kiküldése után 1 hétig lehet befejezni a regisztrációt. Amennyiben ezt az időt már túllépte úgy itt tudsz új meghívót kiküldeni.',
};
