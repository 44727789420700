"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStatus = void 0;
var moment = require("moment/moment");
var getStatus = function (item) {
    var date = moment().add(1, 'minute');
    var itemTo = moment(item.to).endOf('day');
    if (!item.to) {
        if (moment(item.from).isAfter(date, 'day')) {
            return 'future';
        }
        if (moment.utc(item.from).isSameOrBefore(date, 'day')) {
            return 'current';
        }
    }
    if (itemTo.isBefore(date, 'day')) {
        return 'past';
    }
    if (itemTo.isBefore(date, 'minute')) {
        return 'past';
    }
    if (moment(item.from).isSameOrBefore(date, 'minute') && itemTo.isSameOrAfter(date, 'minute')) {
        return 'current';
    }
    if (moment(item.from).isSameOrBefore(date, 'day') && itemTo.isSameOrAfter(date, 'day')) {
        return 'current';
    }
    if (moment(item.from).isAfter(date, 'day') && itemTo.isAfter(date, 'day')) {
        return 'future';
    }
};
exports.getStatus = getStatus;
